/*
 * Description: Vertical menu
 * Version: v1.0.0
 */

@mixin menu-vertical(
    $mixin_menu-vertical--class: 'menu',
    $mixin_menu-vertical--x-margin: 0,
    $mixin_menu-vertical--y-margin: 0,
    $mixin_menu-vertical--text-align: center,
    $mixin_menu-vertical-li--y-padding: 0,
    $mixin_menu-vertical-li--x-padding: 0,
    $mixin_menu-vertical-link--y-padding: 0.5rem,
    $mixin_menu-vertical-link--x-padding: 1rem,
    $mixin_menu-vertical-link-span--decoration: none,
    $mixin_menu-vertical-link-span__hover--decoration: underline,
    $mixin_menu-vertical-link-span__active--decoration: underline
) {
    @include list-unstyled;
    margin: ($mixin_menu-vertical--y-margin) ($mixin_menu-vertical--x-margin);
    text-align: $mixin_menu-vertical--text-align;

    .#{$mixin_menu-vertical--class}-li {
        padding: $mixin_menu-vertical-li--y-padding $mixin_menu-vertical-li--x-padding;

        .#{$mixin_menu-vertical--class}-link {
            padding: $mixin_menu-vertical-link--y-padding $mixin_menu-vertical-link--x-padding;
            text-decoration: none;
            display: inline-block;
            margin: 0;

            &:hover {
                text-decoration: none;

                .link,
                .link:hover {
                    text-decoration: $mixin_menu-vertical-link-span__hover--decoration;
                }
            }

            .link {
                text-decoration: $mixin_menu-vertical-link-span--decoration;
            }
        }
    }
}

.svg-icon {
    vertical-align: middle;
    display: inline-block;
    text-align: center;
    line-height: 1em;
    font-size: 1em;
    height: 1em;
    width: 1em;

    svg {
        vertical-align: top;
        height: 100%;
        width: 100%;
    }

    &.svg-icon-wide {
        width: 1.6em;
    }

    &.svg-icon-sm {
        font-size: 0.8em;
    }

    &.svg-icon-lg {
        margin-top: -0.1em;
        font-size: 1.3em;
    }

    &.svg-icon-xl {
        font-size: 1.6em;
    }

    &.svg-icon-2x {
        font-size: 2em;
    }

    &.svg-icon-3x {
        font-size: 3em;
    }

    &.svg-icon-4x {
        font-size: 4em;
    }

    &.svg-icon-80px {
        font-size: 60px;
    }
}

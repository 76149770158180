.error {
    .form-control,
    .form-select {
        @extend .is-invalid;
    }
}

div:has(.form-error-message) {
    .form-control,
    .form-select {
        @extend .is-invalid;
    }
}

.form-error-message {
    font-style: italic;
    margin-top: 0.5rem;
    font-size: 0.85em;
    color: $danger;
}

.splide {
    @include splide(
        $mixin_splide-show-overflow: false,
        $mixin_splide-custom-arrows: true,
        $mixin_splide-custom-arrows--opacity: 1,
        $mixin_splide-custom-arrows--disabled-opacity: 0,
        $mixin_splide-custom-arrows--svg-color: $white,
        $mixin_splide-custom-arrows--svg-size: 1em,
        $mixin_splide-custom-arrows--bg-color: unset,
        $mixin_splide-custom-pagination: true,
        $mixin_splide-custom-pagination-li--t-margin: $spacer,
        $mixin_splide-custom-pagination-li--b-margin: ($spacer*1.5),
        $mixin_splide-custom-pagination-li--x-margin: 0
    );

    .splide__arrows,
    .splide__pagination {
        display: none;
    }

    &.is-active {
        .splide__arrows,
        .splide__pagination {
            display: block;

            .splide__arrow {
                position: absolute;
            }
        }
    }

    // Hide styling when Splide is not activated
    &:not(.is-active) {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                list-style: none;
                padding: 0;
                margin: 0;
            }
        }
    }

    // Add draggable cursor
    &.splide--draggable {
        cursor: grab;
    }
}

.content-section-gallery {

    picture {

        img {
            object-position: center;
            aspect-ratio: 640/480;
            object-fit: cover;
            width: 100%;
        }
    }
}

/*
 *
 * Animations:
 * - Fade in
 * - ..
 *
 */

// Fade in
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

$link-zoom: false !default;

.link {
    & {
        @include link(
            $mixin_link--decoration: underline,
            $mixin_link__hover--decoration: none,
            $mixin_link--border: false,
        )
    }

    &-inverted {
        @include link(
            $mixin_link--decoration: none,
            $mixin_link__hover--decoration: underline,
            $mixin_link--border: false,
        )
    }

    &-text {
        @include link(
            $mixin_link-element: '.link-text-child',
            $mixin_link--decoration: underline,
            $mixin_link__hover--decoration: none,
            $mixin_link--border: false,
        );

        &-inverted {
            @include link(
                $mixin_link-element: '.link-text-child',
                $mixin_link--decoration: none,
                $mixin_link__hover--decoration: underline,
                $mixin_link--border: false,
            );
        }
    }

    @if $link-zoom == true {
        &-zoom {
            @include link-zoom(
                $link-zoom_transition: 150ms,
                $link-zoom_scale: 1.15,
            )
        }
    }
}

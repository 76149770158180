.video-section {
    @include media-breakpoint-up(lg) {
        height: 78vh;
        position: relative;
        overflow: hidden;
    }

    h1 {
        font-size: 60px;
        line-height: 65px;
        margin-bottom: $spacer*2;

        @include media-breakpoint-down(md) {
            font-size: 40px;
            line-height: 45px;
        }
    }

    iframe {
        aspect-ratio: 16/9;
        min-width: 100%;
        height: 100%;
        width: 100%;
    }

    #_youtube-iframe-wrapper {
        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
        }

        @include media-breakpoint-between(lg, xl) {
            height: 100%;
        }
    }
}

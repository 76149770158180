/* quicksand-regular - latin */
@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-regular.svg#Quicksand') format('svg'); /* Legacy iOS */
}
/* quicksand-500 - latin */
@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    src: url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-500.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-500.svg#Quicksand') format('svg'); /* Legacy iOS */
}
/* quicksand-600 - latin */
@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 600;
    src: url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-600.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-600.svg#Quicksand') format('svg'); /* Legacy iOS */
}
/* quicksand-700 - latin */
@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/site/quicksand-v30-latin/quicksand-v30-latin-700.svg#Quicksand') format('svg'); /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/site/montserrat-v25-latin/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../../fonts/site/montserrat-v25-latin/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/site/montserrat-v25-latin/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/site/montserrat-v25-latin/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/site/montserrat-v25-latin/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/site/montserrat-v25-latin/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('../../fonts/site/montserrat-v25-latin/montserrat-v25-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../../fonts/site/montserrat-v25-latin/montserrat-v25-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/site/montserrat-v25-latin/montserrat-v25-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/site/montserrat-v25-latin/montserrat-v25-latin-600.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/site/montserrat-v25-latin/montserrat-v25-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/site/montserrat-v25-latin/montserrat-v25-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}

// Cookies
.cookies-card {
    background-color: desaturate(darken($light,5%),5%);
    border-top: 1px solid rgba($primary,0.1);
    position: relative;

    .cookie-graphic {
        align-items: center;
        width: 2.25rem;
        display: flex;

        svg {
            height: 100%;
        }

        @include media-breakpoint-up(md) {
            width: 2.5rem;
        }
    }

    .cookie-message {
        align-items: center;
        display: flex;
        flex: 1;
    }

    .cookie-buttons {
        white-space: nowrap;
        align-items: center;
        display: flex;

        @include media-breakpoint-up(sm) {
            padding-left: 2.25rem;
        }

        @include media-breakpoint-up(md) {
            padding-left: 2.5rem;
        }
    }

    .btn-danger {
        background: none;
        border: 0;
        padding-left: 0;
        padding-right: 0;
        font-weight: 400 !important;
        color: $font !important;

        &:hover {
            text-decoration: underline !important;
            color: $font !important;
        }
    }
}

.text {
    // Sizes
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            $text-sizes: (
                "xsmall": 0.7em,
                "small": 0.85em,
                "large": 1.25em,
                "xlarge": 1.5em,
                "2x": 2em,
                "3x": 3em,
                "4x": 4em,
            );

            @each $key, $value in $text-sizes {
                &#{$infix}-#{$key} {
                    font-size: $value;
                }
            }
        }
    }

    // Alignment
    & {
        @include media-breakpoint-up(lg) {
            &-lg {
                &-left {
                    text-align: left !important;
                }
                &-center {
                    text-align: center !important;
                }
                &-right {
                    text-align: right !important;
                }
            }
        }
    }

    // Overflow
    & {
        &-overflow {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}


// Font-style
.fs {
    &-italic {
        font-style: italic !important;
    }
}


// Widths
.w {
    &-auto {
        width: auto !important;
    }
    @include media-breakpoint-up(sm) {
        &-sm {
            &-auto {
                width: auto !important;
            }
        }
    }
    @include media-breakpoint-up(md) {
        &-md {
            &-auto {
                width: auto !important;
            }
        }
    }
    @include media-breakpoint-up(lg) {
        &-lg {
            &-auto {
                width: auto !important;
            }
        }
    }
}


// Flex
.flex {
    &-1 {
        flex: 1 1 0 !important;
    }
}


// Backgrounds
.bg {
    &-none {
        background: none !important;
    }
}


// Margins
.m {
    &b {
        &-lc-0 > :last-child {
            margin-bottom: 0 !important;
        }
    }
}


// Object position
.object {
    &-position {
        &-center {
            object-position: center !important;
        }
    }
}


/* Height */
.h {
    @include media-breakpoint-up(lg) {
        &-lg {
            &-100 {
                height: 100% !important;
            }
        }
    }
}


/* Aspect ratio
---------------------------------------- */
.as {
    &-16x9 {
        aspect-ratio: 16/9 !important;
    }
    &-4x3 {
        aspect-ratio: 4/3 !important;
    }
    &-1x1 {
        aspect-ratio: 1/1 !important;
    }
}


/* Negative offsets
---------------------------------------- */
.offset {
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            $columns: (
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7,
                "8": 8,
                "9": 9,
                "10": 10,
                "11": 11,
                "12": 12
            );

            @each $key, $value in $columns {
                &#{$infix}-n#{$key} {
                    margin-left: (-100% / 12 * $value) !important;
                }
            }
        }
    }
}


// Javascript classes
.js {
    &-hide {
        position: absolute;
        visibility: hidden;
        opacity: 0;
    }

    &-show {
        display: block !important;

        &.d-lg-none {
            display: unset !important;
        }
    }
}

.z {
    &-2 {
        z-index: 2 !important;
    }
    &-3 {
        z-index: 3 !important;
    }
    &-4 {
        z-index: 4 !important;
    }
    &-5 {
        z-index: 5 !important;
    }
}

.faq-item {

    &:not(:last-of-type) {
        .toggle-heading {
            border-bottom: 1px solid $light;
        }
        .answer {
            &.active {
                border-bottom: 1px solid $light;
            }
        }
    }

    .toggle-heading {
        font-family: $headings-font-family;
        padding-inline-end: 2rem;
        position: relative;
        font-weight: 500;
        font-size: 18px;

        &.active {
            border-bottom: 0 !important;
            color: $secondary;

            .more {
                transition: 150ms all ease;
                transform: rotate(90deg);
                color: $secondary;

                span {
                    display: none;
                }
            }
        }

        .more {
            font-family: $headings-font-family;
            transition: 150ms all ease;
            position: absolute;
            font-weight: 300;
            color: $primary;
            display: flex;
            right: 0;
            top: 0;

            svg {
                path {
                    fill: currentColor;
                }
            }
        }
    }

    .answer {
        display: none;

        &.active {
            display: block;
        }
    }
}

// [data-popup],
[data-clickable],
[data-toggle-element] {
    cursor: pointer;

    *:not([href]):not(.tooltip) {
        pointer-events: none;
    }

    [href] {
        pointer-events: visible !important;
    }

    .pe-none {
        pointer-events: none !important;
    }

    .pe-visible {
        * {
            pointer-events: visible !important;
        }
    }

    img,
    figure,
    svg {
        user-select: none;
    }
}

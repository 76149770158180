/*
 * Description: Splide styling
 * Version: v1.0.1
 */

@mixin splide(
    $mixin_splide-show-overflow: false,
    $mixin_splide-custom-arrows: false,
    $mixin_splide-custom-arrows--opacity: 1,
    $mixin_splide-custom-arrows--disabled-opacity: 0,
    $mixin_splide-custom-arrows--svg-color: $white,
    $mixin_splide-custom-arrows--svg-size: 1em,
    $mixin_splide-custom-arrows--bg-color: unset,
    $mixin_splide-custom-pagination: false,
    $mixin_splide-custom-pagination-li--t-margin: $spacer,
    $mixin_splide-custom-pagination-li--b-margin: 0,
    $mixin_splide-custom-pagination-li--x-margin: 0
) {
    @if $mixin_splide-custom-arrows--disabled-opacity == 0 {
        .splide__arrows {
            .splide__arrow[disabled] {
                pointer-events: none;
            }
        }
    }

    @if $mixin_splide-custom-arrows--opacity != $mixin_splide-custom-arrows--disabled-opacity {
        .splide__arrows {
            .splide__arrow {
                @include transition(
                    $mixin_transition--elements: opacity,
                    $mixin_transition--time: 300ms
                )
            }
        }
    }

    @if $mixin_splide-show-overflow == true {
        .splide__track {
            overflow: visible;
        }
    }

    @if $mixin_splide-custom-arrows == true {
        .splide__arrows {
            .splide__arrow {
                background-color: $mixin_splide-custom-arrows--bg-color;

                &[disabled] {
                    opacity: $mixin_splide-custom-arrows--disabled-opacity;
                }

                &:not([disabled]) {
                    opacity: $mixin_splide-custom-arrows--opacity;
                }

                svg {
                    stroke: $mixin_splide-custom-arrows--svg-color;
                    fill: $mixin_splide-custom-arrows--svg-color;
                    height: $mixin_splide-custom-arrows--svg-size;
                    width: $mixin_splide-custom-arrows--svg-size;
                }

                &--prev {
                    svg {
                        transform: unset;
                    }
                }
            }
        }
    }

    @if $mixin_splide-custom-pagination == true {
        .splide__pagination {
            position: relative;
            display: flex;
            bottom: unset;

            li {
                margin: $mixin_splide-custom-pagination-li--t-margin $mixin_splide-custom-pagination-li--x-margin $mixin_splide-custom-pagination-li--b-margin;
            }

            li:only-child {
                display: none;
            }

            .splide__pagination__page {
                margin: 6px;
            }

            .is-active {
                background-color: $primary;
            }
        }
    }
}

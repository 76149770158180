// Html structure example
// <div class="arrow"></div>

@mixin arrow(
    $arrow-prefix: '.',
    $arrow-direction: 'top',
    $arrow-background-color: $white,
    $arrow-border-color: $default,
    $arrow-shadow: 0 0 4px rgba(0,0,0,0.15),
    $arrow-size: 8px,
    $arrow-corner: 1.5,
    $arrow-position-style: relative,
    $arrow-position-top: 0,
    $arrow-position-left: 2rem,
    $arrow-z-index: false
) {
    @if not index(top right bottom left, $arrow-direction) {
        @error "Direction must be either `top`, `right`, `bottom` or `left`.";
    }
    position: $arrow-position-style;

    #{$arrow-prefix}arrow {
        @if $arrow-direction == 'bottom' {
            top: -$arrow-size;
        } @else {
            top: 100%;
        }
        width: ($arrow-size*$arrow-corner);
        left: $arrow-position-left;
        height: $arrow-size;
        position: absolute;

        @if $arrow-z-index {
            z-index: $arrow-z-index;
        }

        &::after,
        &::before {
            position: absolute;
            content: '';
            left: 0;

            $perpendicular-borders: $arrow-size solid transparent;

            @if $arrow-direction == top or $arrow-direction == bottom {
                border-left: $perpendicular-borders;
                border-right: $perpendicular-borders;
            } @else if $arrow-direction == right or $arrow-direction == left {
                border-bottom: $perpendicular-borders;
                border-top: $perpendicular-borders;
            }
        }

        &::before {
            border-#{$arrow-direction}: ($arrow-size * $arrow-corner) solid $arrow-background-color;
            width: ($arrow-size * $arrow-corner);
            height: $arrow-size;
            z-index: 2;
            top: 0;

            @if $arrow-shadow {
                filter: drop-shadow($arrow-shadow);
            }
        }

        @if $arrow-border-color {
            &::after {
                width: calc((#{$arrow-size} * #{$arrow-corner}) + 2px);
                height: calc(#{$arrow-size} + 1px);
                z-index: 1;
                top: 1px;

                border-#{$arrow-direction}: ($arrow-size * $arrow-corner) solid $arrow-border-color;
            }
        }
    }
}

$mobile-menu-background-color: $dark !default;

.mobile-menu-section {
    background-color: $mobile-menu-background-color;
    transition: 450ms all ease;
    pointer-events: none;
    position: fixed;
    top: 0; left: 0;
    height: 100vh;
    color: $white;
    width: 100vw;
    z-index: -1;
    opacity: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;

    &::before {
        background: linear-gradient(180deg, rgba($mobile-menu-background-color,1) 0%, rgba($mobile-menu-background-color,0.9) 75%, rgba($mobile-menu-background-color,0) 100%);
        top: 0; left: 0; right: 0;
        pointer-events: none;
        position: fixed;
        height: 120px;
        content: '';
        z-index: 2;
    }

    .header-section-placeholder {
        pointer-events: none;
        height: 84px;
        width: 100%;
    }

    .container {
        margin: auto;
    }

    .btn-mobile-menu-wrapper {
        @include media-breakpoint-down(lg) {
            @include icon-spacer();
        }

        .js-mobile-header & {
            @include icon-spacer();
        }

        .btn-mobile-menu {
            @include btn-mobile-menu(
                $mixin_btn-mobile-menu--background: false,
                $mixin_btn-mobile-menu--color: $white,
                $mixin_btn-mobile-menu__active--color: false,
                $mixin_btn-mobile-menu__hover--color: false,
                $mixin_btn-mobile-menu--size: 16px,
                $mixin_btn-mobile-menu--padding: 0
            );
        }
    }

    .logo-container {
        svg {
            height: 52px;
            width: auto;

            @media all and (min-width: 1400px) {
                height: 97px;
            }
        }
    }

    .mobile-menu {
        .menu {
            @include menu-vertical(
                $mixin_menu-vertical--class: 'menu',
                $mixin_menu-vertical--x-margin: 0,
                $mixin_menu-vertical--y-margin: 0,
                $mixin_menu-vertical--text-align: center,
                $mixin_menu-vertical-li--y-padding: 0,
                $mixin_menu-vertical-li--x-padding: 0,
                $mixin_menu-vertical-link--y-padding: 0.5rem,
                $mixin_menu-vertical-link--x-padding: 1rem,
                $mixin_menu-vertical-link-span--decoration: none,
                $mixin_menu-vertical-link-span__hover--decoration: none,
                $mixin_menu-vertical-link-span__active--decoration: none
            );

            li.active .link {
                color: $secondary !important;
            }

            .submenu {
                @include dropdown(
                    $mixin_dropdown-trigger--padding: 0,
                    $mixin_dropdown-trigger--border-radius: 0,
                    $mixin_dropdown-trigger__active: false,
                    $mixin_dropdown-trigger__active--bg: false,
                    $mixin_dropdown-trigger__active--color: false,

                    $mixin_dropdown-arrow: false,
                    $mixin_dropdown-arrow--z-index: -1,
                    $mixin_dropdown-arrow--size: 8px,
                    $mixin_dropdown-arrow--corner: 1.15,

                    $mixin_dropdown-content--border-radius: false,
                    $mixin_dropdown-content--position: relative,
                    $mixin_dropdown-content--position-top: false,
                    $mixin_dropdown-content--position-left: false,
                    $mixin_dropdown-content--width: false,
                    $mixin_dropdown-content--bg: false,
                    $mixin_dropdown-content--color: false,
                    $mixin_dropdown-content--box-shadow: false,
                );

                .svg-icon {
                    transition: 150ms transform ease;
                    margin-right: -12px;
                }

                &.active-dropdown {
                    .svg-icon {
                        transform: rotate(180deg);
                        transition: 150ms transform ease;
                    }
                }

                .dropdown-ul {
                    @include menu-vertical(
                        $mixin_menu-vertical--class: 'dropdown',
                        $mixin_menu-vertical--x-margin: 0,
                        $mixin_menu-vertical--y-margin: 0,
                        $mixin_menu-vertical--text-align: center,
                        $mixin_menu-vertical-li--y-padding: 0,
                        $mixin_menu-vertical-li--x-padding: 0,
                        $mixin_menu-vertical-link--y-padding: 0.5rem,
                        $mixin_menu-vertical-link--x-padding: 1rem,
                        $mixin_menu-vertical-link-span--decoration: none,
                        $mixin_menu-vertical-link-span__hover--decoration: underline,
                        $mixin_menu-vertical-link-span__active--decoration: underline
                    );
                }
            }
        }
    }

    // Animations
    .container {
        opacity: 0;

        &:nth-child(2) {
        }
    }

    @at-root .active-mobile-menu {
        overflow: hidden;

        body {
            overflow: hidden;
        }
    }

    .active-mobile-menu & {
        z-index: ($zindex-sticky - 1);
        pointer-events: visible;
        opacity: 1;

        .container {
            transform: translateY(0);
            opacity: 1;

            li {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
}

@use 'sass:math';

.form-label {
    &-req {
        &::after {
            display: inline-block;
            color: $danger;
            content: ' *';
        }
    }
}

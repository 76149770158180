.usps-slider-section {
    .svg-icon {
        font-size: 60px;
        width: 3em;
    }

    // Dual sided icon
    .dual-sided-cls-1,
    .dual-sided-cls-2 {
        stroke-width: 4px;
    }

    .splide {
        .splide__pagination {
            margin: 2rem 0 0;
            padding: 0;

            li {
                margin: 0;
            }
        }
    }
}

.usps-overview-section {
    .svg-icon {
        font-size: 60px;
        max-width: 3em;
        width: auto;
    }

    a {
        text-decoration: none;
        font-weight: 600;
        color: $primary;
    }
}

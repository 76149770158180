.pillow-intro-section {
    clip-path: polygon(0 -100%, 0 100%, 100% 100%, 100% -100%);
    background-color: $dark;
    pointer-events: none;

    .container {
        position: relative;

        .row {
            position: relative;
            z-index: 2;
        }

        .pillow-intro-section-gradient {
            background: radial-gradient(circle, rgba(45,91,101,1) 0%, rgba(45,91,101,0) 60%);
            transform: translate(-50%,-50%) scale(1.3);
            position: absolute;
            aspect-ratio: 1/1;
            width: 100%;
            left: 50%;
            top: 50%;
        }

        @keyframes pillowBrandname {
            0% {
                opacity: 0;
            }
            100% {
                transform: scale(1.125);
                opacity: 1;
            }
        }

        @keyframes pillowImage {
            0% {
                transform: translateY(50vw) scale(0);
                opacity: 0;
            }
            100% {
                transform: translateY(0) scale(1);
                opacity: 1;
            }
        }

        .pillow-brandname {
            line-height: 0.75;
            font-size: 18vw;

            //transform: scale(0);
            opacity: 0;
            animation: pillowBrandname 1200ms forwards 500ms;
        }

            .pillow-intro-section-title {
                position: relative;
                z-index: 2;

                -webkit-text-stroke: 0.25rem $light;
                color: #1d3238;
            }

            .pillow-intro-section-title-gradient {
                background: linear-gradient(to top, #005c6c, #f5bc00);
                background-clip: text;
                filter: blur(0.75rem);
                color: transparent;
                position: absolute;
                left: 0;
                top: 0;

                &:last-child {
                    filter: blur(0.5rem);
                }
            }

        .pillow {
            position: absolute;
            bottom: -1rem;

            transform: scale(0) translateY(50vw);
            opacity: 0;
            animation: pillowImage 1000ms forwards 500ms;
        }
    }
}



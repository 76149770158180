.whatsapp-corner {
    position: fixed;
    bottom: $spacer*2;
    right: $spacer*2;
    z-index: 99;
    background-color: $white;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.16);
    transition: 200ms all ease-in-out;

    &:hover {
        background-color: #25d366;

        svg {
            path {
                fill: $white !important;
            }
        }
    }

    svg {
        width: 50px;
        height: auto;

        path {
            fill: #25d366 !important;
        }
    }
}

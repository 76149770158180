.intro-section {

    // Order overview?
    &.bg-tertiary {
        li {
            display: flex;
            align-items: center;
            padding-bottom: math.div($spacer,2.5);

            &:first-child {
                .svg-icon {
                    font-size: 40px;
                }
            }

            .svg-icon {
                margin-right: $spacer;
                font-size: 35px;

                svg {
                    path {
                        fill: $primary;
                    }
                }
            }
        }
    }

    .arrow svg {
        width: auto;
        height: 120px;
        position: absolute;
        bottom: -50px;
        right: -20px;
        transform: scaleX(-1);

        @include media-breakpoint-down(lg) {
            height: 80px;
            right: 20px;
        }

        path {
            fill: $primary;
        }
    }

    .body_pillow & {
        height: 85vh;

        @include media-breakpoint-down(xl) {
            height: auto;
        }

        .container {
            position: relative;
            z-index: 2;
        }

        .btn-secondary {
            &::after {
                content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47.73 25.36'><path d='M35.29,.12l12.33,12.33c.14,.11,.14,.34,0,.48l-12.33,12.33c-.14,.14-.34,.14-.48,0l-1.59-1.59,9.73-9.72H1.39C.62,13.95,0,13.33,0,12.56c0-.74,.62-1.36,1.39-1.36H42.72L33.22,1.69l1.59-1.59c.14-.14,.34-.14,.48,0h0Z' fill='%23ffffff'/></svg>");
            }
        }

        img {
            position: absolute;
            top: 0; left: 0;
            object-fit: cover;
            object-position: top right;
            width: 2560px;
            height: 100%;

            @include media-breakpoint-down(xl) {
                width: unset;
                height: unset;
                position: relative;
                top: auto; left: auto;
            }
        }
    }
}

/*
 * Description: Dropdown
 * Version: v1.0.2
 */

@mixin dropdown(
    $mixin_dropdown-trigger--position: relative,
    $mixin_dropdown-trigger--padding: '',
    $mixin_dropdown-trigger--border-radius: 4px 4px 0 0,
    $mixin_dropdown-trigger__active: true,
    $mixin_dropdown-trigger__active--bg: $white,
    $mixin_dropdown-trigger__active--color: $primary,
    $mixin_dropdown-arrow: false,
    $mixin_dropdown-arrow--box-shadow: 0 0 4px rgba(0, 0, 0, 0.15),
    $mixin_dropdown-arrow--z-index: 1,
    $mixin_dropdown-arrow--size: 8px,
    $mixin_dropdown-arrow--corner: 1.25,
    $mixin_dropdown-arrow--position-left: $spacer,
    $mixin_dropdown-content--position: absolute,
    $mixin_dropdown-content--position-top: 0,
    $mixin_dropdown-content--position-left: 0,
    $mixin_dropdown-content--width: 160px,
    $mixin_dropdown-content--border: $white,
    $mixin_dropdown-content--border-radius: 0 4px 4px 4px,
    $mixin_dropdown-content--bg: $white,
    $mixin_dropdown-content--color: $primary,
    $mixin_dropdown-content--box-shadow: $box-shadow,
) {
    position: $mixin_dropdown-trigger--position;
    user-select: none;

    @if $mixin_dropdown-trigger--padding != '' {
        .dropdown-trigger {
            padding: $mixin_dropdown-trigger--padding;
        }
    }

    @if $mixin_dropdown-trigger__active == true {
        &.active-dropdown > .dropdown-trigger {
            border-radius: $mixin_dropdown-trigger--border-radius;
            background-color: $mixin_dropdown-trigger__active--bg;
            color: $mixin_dropdown-trigger__active--color;
            position: relative;
            z-index: 2;
        }
    }

    // Hide chevron if there's no javascript
    .no-js & {
        .svg-icon {
            display: none;
        }
    }

    @if $mixin_dropdown-arrow == true {
        @include arrow(
            $arrow-prefix: '.dropdown-',
            $arrow-direction: 'bottom',
            $arrow-background-color: $mixin_dropdown-content--bg,
            $arrow-border-color: $mixin_dropdown-content--border,
            $arrow-shadow: $mixin_dropdown-arrow--box-shadow,
            $arrow-size: $mixin_dropdown-arrow--size,
            $arrow-corner: $mixin_dropdown-arrow--corner,
            $arrow-position-style: relative,
            $arrow-position-top: -$mixin_dropdown-arrow--size,
            $arrow-position-left: $mixin_dropdown-arrow--position-left,
            $arrow-z-index: $mixin_dropdown-arrow--z-index
        );
    }

    .dropdown-content {
        pointer-events: none;
        white-space: normal;
        opacity: 0;

        @if $mixin_dropdown-content--color != false {
            color: $mixin_dropdown-content--color;
        }

        @if $mixin_dropdown-content--bg != false {
            background-color: $mixin_dropdown-content--bg;
        }

        @if $mixin_dropdown-content--border-radius != false {
            border-radius: $mixin_dropdown-content--border-radius;
        }

        @if $mixin_dropdown-content--box-shadow != false {
            box-shadow: $mixin_dropdown-content--box-shadow;
        }

        @if $mixin_dropdown-content--position != absolute {
            display: none;
        }

        @if $mixin_dropdown-content--width != false {
            width: $mixin_dropdown-content--width;
        }

        @if $mixin_dropdown-content--position == absolute {
            top: calc(100% + #{$mixin_dropdown-content--position-top});
            left: $mixin_dropdown-content--position-left;
            position: absolute;
        }

        @if $mixin_dropdown-content--position-left == 50% {
            transform: translateX(-50%);
        }

        > ul {
            border-radius: inherit;
            overflow: hidden;

            li {
                &.active {
                    border-color: rgba(0, 0, 0, 0.125);
                    background-color: unset;
                    border-top: 0;
                }
            }
        }
    }

    &.active-dropdown {
        .dropdown-content {
            pointer-events: visible;
            opacity: 1;

            @if $mixin_dropdown-content--position != absolute {
                display: block;
            }
        }
    }
}

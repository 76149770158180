/*
 * Description: Horizontal menu
 * Version: v1.2.0
 */

@mixin menu-horizontal(
    $mixin_menu-horizontal--class:                            menu,
    $mixin_menu-horizontal--x-margin:                         -2rem,
    $mixin_menu-horizontal--y-margin:                         0,
    $mixin_menu-horizontal-li--y-padding:                     0.5rem,
    $mixin_menu-horizontal-li--x-padding:                     2rem,
    $mixin_menu-horizontal-link-element:                      '.link',
    $mixin_menu-horizontal-link--padding:                     0.75rem 1rem,
    $mixin_menu-horizontal-link--color:                       inherit,
    $mixin_menu-horizontal-link--decoration:                  none,
    $mixin_menu-horizontal-link__hover--color:                inherit,
    $mixin_menu-horizontal-link__hover--decoration:           none,
    $mixin_menu-horizontal-link--border-color:                currentColor,
    $mixin_menu-horizontal-link--border-height:               2px
) {
    display: inline-block;
    white-space: nowrap;
    list-style: none;
    font-size: 0;
    padding: 0;
    margin: ($mixin_menu-horizontal--y-margin) ($mixin_menu-horizontal--x-margin);

    .#{$mixin_menu-horizontal--class}-li {
        padding: $mixin_menu-horizontal-li--y-padding $mixin_menu-horizontal-li--x-padding;
        display: inline-block;
        font-size: 1rem;
        margin: 0;

        .#{$mixin_menu-horizontal--class}-link {
            @include link(
                $mixin_link-element:                      $mixin_menu-horizontal-link-element,
                $mixin_link--padding:                     $mixin_menu-horizontal-link--padding,
                $mixin_link--color:                       $mixin_menu-horizontal-link--color,
                $mixin_link--decoration:                  $mixin_menu-horizontal-link--decoration,
                $mixin_link__hover--color:                $mixin_menu-horizontal-link__hover--color,
                $mixin_link__hover--decoration:           $mixin_menu-horizontal-link__hover--decoration,
                $mixin_link--border-top:                  calc(100% + 3px),
                $mixin_link--border-color:                $mixin_menu-horizontal-link--border-color,
                $mixin_link--border-height:               $mixin_menu-horizontal-link--border-height,
                $mixin_link--border-width:                0,
                $mixin_link__hover--border-width:         100%
            )
        }

        &.active {
            .#{$mixin_menu-horizontal--class}-link {
                @include link(
                    $mixin_link-element:                      $mixin_menu-horizontal-link-element,
                    $mixin_link--padding:                     $mixin_menu-horizontal-link--padding,
                    $mixin_link--color:                       $mixin_menu-horizontal-link--color,
                    $mixin_link--decoration:                  $mixin_menu-horizontal-link--decoration,
                    $mixin_link__hover--color:                $mixin_menu-horizontal-link__hover--color,
                    $mixin_link__hover--decoration:           $mixin_menu-horizontal-link__hover--decoration,
                    $mixin_link--border-top:                  calc(100% + 3px),
                    $mixin_link--border-color:                $mixin_menu-horizontal-link--border-color,
                    $mixin_link--border-height:               $mixin_menu-horizontal-link--border-height,
                    $mixin_link--border-width:                100%,
                    $mixin_link__hover--border-width:         100%
                )
            }
        }
    }
}

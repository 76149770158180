/*
 * Description: Transition
 * Version: v1.0.0
 */

@mixin transition(
    $mixin_transition--elements:   all,
    $mixin_transition--speed:      default,
    $mixin_transition--time:       '',
    $mixin_transition--delay:      0ms,
    $mixin_transition--type:       ease,
    $mixin_transition--state:      default
) {
    @if $mixin_transition--speed != '' {
        @if $mixin_transition--speed == 'default' {
            transition-duration: 300ms;

            @if $mixin_transition--state == hover {
                &:hover {
                    transition-duration: 300ms;
                }
            } @if $mixin_transition--state == active {
                &.active {
                    transition-duration: 300ms;
                }
            }

        } @else if $mixin_transition--speed == 'fast' {
            transition-duration: 150ms;

            @if $mixin_transition--state == hover {
                &:hover {
                    transition-duration: 150ms;
                }
            } @if $mixin_transition--state == active {
                &.active {
                    transition-duration: 150ms;
                }
            }
        }
    }

    @if $mixin_transition--time != '' {
        transition-duration: $mixin_transition--time;

        @if $mixin_transition--state == hover {
            &:hover {
                transition-duration: $mixin_transition--time;
            }
        } @if $mixin_transition--state == active {
            &.active {
                transition-duration: $mixin_transition--time;
            }
        }
    }

    transition-property: $mixin_transition--elements;
    transition-timing-function: $mixin_transition--type;
    transition-delay: $mixin_transition--delay;
}

.reviews-section {
    font-weight: 500;
    font-size: 24px;
    color: $primary;

    @include media-breakpoint-down(md) {
        font-size: 18px;
    }

    .quote {
        position: relative;
    }

    p {
        position: relative;
        z-index: 2;
    }

    .author {
        position: relative;
        z-index: 2;
        font-weight: 600;
        font-size: 32px;
        color: $secondary;

        @include media-breakpoint-down(md) {
            font-size: 22px;
        }
    }

    svg {
        position: absolute;
        top: 0;
        left: -100px;
        width: auto;
        height: 165px;

        path {
            fill: $tertiary;
            stroke: none;

            @at-root .reviews-section.bg-light svg path {
                fill: darken($tertiary,7%);
            }
        }
    }

    .splide__pagination {
        text-align: center;
    }

    .splide__pagination__page {
        background: $primary;

        &.is-active {
            background: $secondary;
        }
    }
}

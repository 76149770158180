/*
 * Description: List-icon
 * Version: v1.0.0
 */

@mixin list-icon(
    $mixin_list-icon--size: 1.3,
    $mixin_list-icon--color: $primary,
    $mixin_list-icon--check: true,
    $mixin_list-icon--plus: false,
    $mixin_list-icon--custom-icon: false,
    $mixin_list-icon--custom-icon__class: 'check',
    $mixin_list-icon--custom-icon__code: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#{$primary}"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/></svg>',
) {
    padding-left: 0;
    margin-left: 0;

    li {
        padding-left: ($mixin_list-icon--size+0.5)+em;
        margin-bottom: 0.2em;
        line-height: 1.5;
        list-style: none;

        &::before {
            background-position: center left;
            height: $line-height-base+em;
            background-repeat: no-repeat;
            margin: (-(($mixin-list-icon--size - 1)/2)+em) 0 0 (-($mixin_list-icon--size+0.5)+em);
            vertical-align: text-top;
            background-size: $mixin_list-icon--size+em;
            display: inline-block;
            line-height: 1em;
            width: ($mixin_list-icon--size+0.5)+em;
            content: '';
        }
    }

    @if $mixin_list-icon--custom-icon == true {
        &.list-icon--#{$mixin_list-icon--custom-icon__class} {
            li {
                &::before {
                    @include background-svg(
                        $svg: $mixin_list-icon--custom-icon__code
                    )
                }
            }
        }
    }

    @if $mixin_list-icon--check == true {
        &.list-icon--check {
            li {
                &::before {
                    @include background-svg(
                        $svg: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#{$mixin_list-icon--color}"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/></svg>'
                    )
                }
            }
        }
    }

    @if $mixin_list-icon--plus == true {
        &.list-icon--plus {
            li {
                &::before {
                    @include background-svg(
                        $svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon_plus" fill="#{$mixin_list-icon--color}" viewBox="-4.5 -4.5 24 24"><path d="M8.9 6.9v-5a1 1 0 10-2 0v5h-5a1 1 0 100 2h5v5a1 1 0 102 0v-5h5a1 1 0 100-2h-5z"/></svg>'
                    )
                }
            }
        }
    }
}

/*
 * Description: Stretch element till edge
 * Version: v1.1.1
 */

@mixin stretch-till-edge (
    $mixin-stretch-till-edge--col-container: 8,
    $mixin-stretch-till-edge--col-padding: $spacer,
    $mixin-stretch-till-edge--site-wrapper-width: $site-wrapper_width,
    $mixin-stretch-till-edge--direction: right
) {
    @if $mixin-stretch-till-edge--site-wrapper-width == false {
        $mixin-stretch-till-edge--site-wrapper-width: 100vw
    }
    @if $mixin-stretch-till-edge--col-padding == false {
        $mixin-stretch-till-edge--col-padding: 0
    }

    @if $mixin-stretch-till-edge--col-padding == 0 {
        width:
            min(
                calc(calc(calc(100vw - (100% / #{$mixin-stretch-till-edge--col-container} * 12)) / 2) + 100%),
                calc(calc(calc(calc(#{$mixin-stretch-till-edge--site-wrapper-width} - (100% / #{$mixin-stretch-till-edge--col-container} * 12))) / 2) + 100%)
            );
    } @else {
        width:
            min(
                calc(calc(calc(calc(100vw - (#{$mixin-stretch-till-edge--col-padding}*2)) - (100% / #{$mixin-stretch-till-edge--col-container} * 12)) / 2) + 100%),
                calc(calc(calc(calc(#{$mixin-stretch-till-edge--site-wrapper-width} - (#{$mixin-stretch-till-edge--col-padding}*2)) - (100% / #{$mixin-stretch-till-edge--col-container} * 12)) / 2) + 100%)
            );
    }

    &:is(img) {
        max-width: unset;
    }

    @if $mixin-stretch-till-edge--direction == left {
        float: right;
    }
}

@mixin new-stretch-till-edge (
    $col-container: 8,
    $col-offset: 0,
    $col-padding: $spacer,
    $site-wrapper-width: $site-wrapper-width,
    $direction: right
) {
    $wrapper-width: if($site-wrapper-width == false, 100dvw, $site-wrapper-width);
    $padding: if($col-padding == false, 0, $col-padding);
    $column-width: 100% / $col-container * 12;

    display: flex !important;
    align-content: flex-start;
    height: 100%;

    @if $direction == both {
        @if $padding == 0 {
            margin-left: calc(-1 * (#{$wrapper-width} - #{$column-width}) / 2);
            width: #{$wrapper-width};
        }
    } @else {
        @if $padding == 0 {
            width: calc(((#{$wrapper-width} - #{$column-width}) / 2) + 100%);
        } @else {
            width: calc(
                (((#{$wrapper-width} - ((#{$padding}*2 + 100%) / #{$col-container} * 12)) / 2)
                + (#{$padding}*2 + 100%))
                - #{$padding}
            );
        }

        @if $direction == left {
            float: right;
        }
    }

    @if $col-offset != 0 {
        position: relative;
        $offset-calc: if(
            $direction == 'left' or $direction == 'both',
            calc(-1 * (100% / #{$col-container}) * #{$col-offset}),
            calc((100% / #{$col-container}) * #{$col-offset})
        );

        #{if($direction == 'left' or $direction == 'both', left, right)}: #{$offset-calc};
    }

    &:is(img) {
        max-width: unset;
    }
}

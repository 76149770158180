@mixin tooltip(
    $tooltip-background-color: $white,
    $tooltip-color: $font,
    $tooltip-border-color: 0,
    $tooltip-position-horizontal: 0,
    $tooltip-position-vertical: 100%,
    $tooltip-arrow-size: 8px,
    $tooltip-arrow-corner: 1.25,
    $tooltip-padding: $spacer,
    $tooltip-width: 240px
) {
    position: relative;

    &:not(.active) {
        overflow: hidden;
    }

    .tooltip {
        box-shadow: 0 4px 12px rgba($dark, 0.15);
        background-color: $tooltip-background-color;
        border: 1px solid $tooltip-border-color;
        left: $tooltip-position-horizontal;
        margin-top: $tooltip-arrow-size;
        top: $tooltip-position-vertical;
        border-radius: $border-radius;
        color: $tooltip-color;
        width: $tooltip-width;
        position: absolute;
        visibility: hidden;
        cursor: auto;
        z-index: 1;
        padding: 0;

        @include arrow(
            $arrow-prefix: '.tooltip-',
            $arrow-direction: 'bottom',
            $arrow-background-color: $tooltip-background-color,
            $arrow-border-color: $tooltip-border-color,
            $arrow-shadow: 0 0 4px rgba(0,0,0,0.15),
            $arrow-size: $tooltip-arrow-size,
            $arrow-corner: $tooltip-arrow-corner,
            $arrow-position-style: absolute,
            $arrow-position-top: -$tooltip-arrow-size,
            $arrow-position-left: $tooltip-padding,
            $arrow-z-index: false
        );

        &.tooltip-align-bottom {
            bottom: calc(#{$tooltip-position-vertical} + (#{$tooltip-arrow-size} * #{$tooltip-arrow-corner})) ;
            top: unset;

            .tooltip-arrow {
                &::before {
                    border-bottom: unset;
                    border-top: calc(#{$tooltip-arrow-size} * #{$tooltip-arrow-corner}) solid $tooltip-background-color;
                    height: calc(#{$tooltip-arrow-size} * #{$tooltip-arrow-corner});
                }
            }

            .tooltip-arrow,
            .tooltip-arrow-border {
                bottom: unset;
                top: 100%;
            }
        }

        &.tooltip-align-right {
            right: $tooltip-position-horizontal;
            left: unset;

            .tooltip-arrow,
            .tooltip-arrow-border {
                right: calc(#{$tooltip-padding} + (#{$tooltip-arrow-size}));
                left: unset;
            }
        }

        &.active {
            visibility: visible;
            opacity: 1;
        }

        .tooltip-content {
            background-color: $tooltip-background-color;
            padding: $tooltip-padding;
            border-radius: inherit;
            position: relative;
            z-index: 2;
        }
    }
}

.tooltip-trigger {
    @include tooltip(

    );
}

$dependancy: url('resources/scss/site/mixins/components/_list-icon.scss');

.list-icon {
    @include list-icon(
        $mixin_list-icon--size: 1.3,
        $mixin_list-icon--color: $primary,
        $mixin_list-icon--check: true,
        $mixin_list-icon--plus: false,
        $mixin_list-icon--custom-icon: false,
        $mixin_list-icon--custom-icon__class: 'check',
        $mixin_list-icon--custom-icon__code: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#{$primary}"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/></svg>',
    );
}

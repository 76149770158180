.scan-animation {
    overflow: hidden;
}

.scan-animation-frame {

}

.scan-animation-screen {
    position: absolute !important;
    top: 2%;
    left: 16%;
    right: 16%;
    bottom: 4%;
    z-index: 8;
}

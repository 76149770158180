/*
 * Description: Link
 * Version: v1.0.0
 */

@mixin link(
    $mixin_link-element:                      false,
    $mixin_link--padding:                     0,
    $mixin_link--color:                       inherit,
    $mixin_link__hover--color:                inherit,
    $mixin_link--decoration:                  $link-decoration,
    $mixin_link__hover--decoration:           $link-hover-decoration,
    $mixin_link--border:                      true,
    $mixin_link--border-top:                  100%,
    $mixin_link--border-color:                currentColor,
    $mixin_link--border-height:               2px,
    $mixin_link--border-width:                0,
    $mixin_link__hover--border-width:         100%
) {
    padding: $mixin_link--padding;
    color: $mixin_link--color;
    display: inline-block;

    @if $mixin_link-element == false {
        text-decoration: $mixin_link--decoration;

        &:hover,
        a:hover & {
            text-decoration: $mixin_link__hover--decoration;
            color: $mixin_link__hover--color;
        }
    } @else {
        & {
            text-decoration: none;

            #{$mixin_link-element} {
                text-decoration: $mixin_link--decoration;
                color: $mixin_link--color;
                position: relative;

                @if $mixin_link--border != false {
                    &::after {
                        @include transition();
                        background-color: $mixin_link--border-color;
                        height: $mixin_link--border-height;
                        position: absolute;
                        display: block;
                        content: '';
                        top: $mixin_link--border-top;
                        width: $mixin_link--border-width;
                        left: 0;
                    }
                }
            }

            &:hover {
                color: $mixin_link__hover--color;
                text-decoration: none;

                #{$mixin_link-element} {
                    text-decoration: $mixin_link__hover--decoration;
                    color: $mixin_link__hover--color;

                    @if $mixin_link--border != false {
                        &::after {
                            width: $mixin_link__hover--border-width;
                        }
                    }
                }
            }
        }
    }
}

// Cookies
.cookies-section {
    z-index: $zindex-cookiebar;
    background-color: $light;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;

    .cookie-graphic {
        align-items: center;
        flex-shrink: 0;
        display: flex;
    }

    .cookie-message {
        align-items: center;
        display: flex;
        flex-grow: 1;
    }

    .cookie-buttons {
        white-space: nowrap;
        align-items: center;
        flex-shrink: 0;
        display: flex;

        .btn {
            padding-inline: 1rem;

            @include media-breakpoint-down(md) {
                font-size: max(1rem, 16px);

                @media all and (max-width: 379px) {
                    font-size: max(1rem, 12.5px);
                    padding-inline: 0.75rem;
                }
            }

            &::after,
            &::before {
                content: none;
            }
        }
    }
}

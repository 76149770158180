main {
    &:has(section.bg-light:last-child) {
        position: relative;

        &::after {
            background: $light;
            position: absolute;
            height: 100px;
            width: 100px;
            content: '';
            z-index: 0;
            top: 100%;
        }

        + .footer-section {
            background-color: $light;
        }
    }
}

// Footer
.footer-section {
    background-color: $white;
    position: relative;
    color: $white;
    z-index: 2;

    &::before {
        border-radius: $spacer*2 0 0 0;
        background-color: $dark;
        position: absolute;
        content: '';
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
    }

    main section.bg-light:last-child & {
        &::before {
            background-color: red;
        }
    }

    .container {
        position: relative;
    }

    .logo {
        display: block;

        svg {
            max-width: 100%;
            width: 220px;
            height: auto;

            @include media-breakpoint-down(sm) {
                max-width: 30vw;
            }
        }
    }

    .contact-info {
        font-weight: 600;
    }

    a {
        @extend .link-inverted;
    }

    .menu {
        margin: 0;

        li {
            margin-bottom: 2px;
        }
    }

    .footer-socialmedia {
        &:not(:has(ul)) {
            display: none !important;
        }

        .social-title {
            font-weight: 600;
        }

        .socialmedia {
            @extend .list-inline;

            a {
                .svg-icon {
                    transition: 200ms all ease-in;
                    display: inline-block;
                    transform-origin: center;
                    transform: scale(1);
                    background-color: $secondary;
                    border-radius: 50%;
                    padding: 2px;
                    font-size: 1.4em;
                    margin-top: 10px;
                }

                &:hover {
                    .svg-icon {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }

    .footer-menu, .footer-menu2 {
        .link {
            @extend .link-inverted;
        }

        li {
            &::before {
                content: '»';
                padding-right: 5px;
            }
        }
    }
}

.copyright-section {
    border-color: lighten($dark,7%) !important;
}

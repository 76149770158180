// Bootstrap defaults
$bootstrap-default-vars:                url('~/bootstrap/scss/variables');

// Html
$html--default-scrollbar:               true;

// Site wrapper
$site-wrapper_width:                    100vw;
$site-wrapper_box-shadow:               0 0 80px rgba(0,0,0,0.1);

$lazyload-animation-time:               300ms;

// Bootstrap overrides
$enable-negative-margins:               true;

// Color system
@import 'variables/colors';

// Grid system
$spacer:                                1rem !default;
$spacers: (
    0:                                  0,
    1:                                  $spacer * .25,
    2:                                  $spacer * .5,
    3:                                  $spacer,
    4:                                  $spacer * 2,
    5:                                  $spacer * 3,
    6:                                  $spacer * 4,
) !default;

$grid-columns:                          12 !default;
$grid-gutter-width:                     2rem !default;
$grid-row-columns:                      6 !default;

$gutters:                               $spacers !default;

$grid-breakpoints: (
    xs:                                 0,
    sm:                                 576px,
    md:                                 768px,
    lg:                                 992px,
    xl:                                 1200px,
    xxl:                                1400px
);


// Body
$body-bg:                               $white;
$body-color:                            $gray-900;


// Typography

// Sizes
$text-size-xsmall:                      0.70em;
$text-size-small:                       0.85em;
$text-size-large:                       1.25em;
$text-size-xlarge:                      1.50em;

// Bodytext
$font-family-base:                      'Quicksand', sans-serif;
$line-height-base:                      1.6;
$font-size-base:                        1rem;

$margin-bottom:                         $line-height-base+rem;

// Headings
$headings-font-family:                  'Quicksand', sans-serif;
$headings-font-weight:                  500;
$headings-line-height:                  1.2 !default;
$headings-color:                        inherit;

$h1-font-size:                          $font-size-base * 3;
$h2-font-size:                          $font-size-base * 2.5;
$h3-font-size:                          $font-size-base * 2;
$h4-font-size:                          $font-size-base * 1.5;
$h5-font-size:                          $font-size-base * 1.25;
$h6-font-size:                          $font-size-base;

// Responsive Headings
// $h1-font-size:                        clamp(1.5em, calc(1.3em + 1vw), 3em);
// $headings-margin-bottom:              0.5em !default;


// Display
$display-font-sizes: (
    1:                                  5rem
);

// $display1-size:                       6rem !default;
// $display2-size:                       5.5rem !default;
// $display3-size:                       4.5rem !default;
// $display4-size:                       3.5rem !default;

// Link
$link-color:                            inherit;
$link-decoration:                       underline;
$link-hover-color:                      inherit;
$link-hover-decoration:                 none;


// Form
// $input-btn-focus-box-shadow:           none;
// $btn-focus-box-shadow:                none;
$border-color:                          $primary;

$input-btn-padding-y:                   0.6rem !default; // Note keep the same as form padding
$input-btn-padding-x:                   1rem !default;

// Button
$btn-font-family:                       $headings-font-family;
$btn-font-weight:                       500;
$btn-padding-y:                         $input-btn-padding-y !default; // Note keep the same as form padding
$btn-padding-x:                         ($input-btn-padding-x*1.5);
$btn-wide-padding-x:                    false;
$btn-text-transform:                    false;
$btn-rounded-radius:                    false;


// Box-shadows
// $box-shadow-sm:                       0 .125rem 0.25rem rgba($black, 0.075) !default;
// $box-shadow:                          0 .375rem 0.75rem rgba($black, 0.075);
// $box-shadow-lg:                       0 .500rem 1.50rem rgba($black, 0.075);


// Breadcrumb
$breadcrumb-divider:                    quote("»") !default;
// $breadcrumb-bg:                       #fff;
// $breadcrumb-padding-y:                0;
// $breadcrumb-padding-x:                0;
// $breadcrumb-margin-bottom:            2rem;

// List-group
$list-group-color:                      inherit;
$list-group-bg:                         unset;

// @import 'variables/input';
// @import 'variables/btn';

$zindex-dropdown:                       1000 !default;
$zindex-sticky:                         1020 !default;
$zindex-fixed:                          1030 !default;
$zindex-offcanvas-backdrop:             1040 !default;
$zindex-offcanvas:                      1045 !default;
$zindex-modal-backdrop:                 1050 !default;
$zindex-modal:                          1055 !default;
$zindex-popover:                        1070 !default;
$zindex-tooltip:                        1080 !default;
$zindex-cookiebar:                      1090;

$max-height: min(800px, calc(100svh - 160px - 4rem));
$max-height-content: min(calc(800px - 4rem), calc(100svh - 160px - 8rem));

.scan-stepper {
    flex-grow: 1;
    gap: 4rem;

    @include media-breakpoint-up(lg) {
        gap: 15vh;

        > div {
            padding-block: 0 !important;
            height: $max-height-content;
            justify-content: center;
            flex-direction: column;
            display: flex;
        }
    }

    .step-image-mobile {
        @include media-breakpoint-down(lg) {
            max-width: min(60vw, 320px);
        }

        .step-image-mobile-app {
            position: absolute;
            bottom: 0.5rem;
            top: 0.5rem;
            right: 0;
            left: 0;
        }
    }
}

.scan-explainer-sticky {
    top: max(104px, calc((100svh - $max-height)/2));
    height: $max-height;
    align-items: center;
    margin-top: -4rem;
    position: sticky;
}

.step-image-desktop-app {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
}

[id^="step"] {
    scroll-margin-block-start: 104px;

    @include media-breakpoint-up(lg) {
        scroll-margin-block-start: max(104px, (((100svh - $max-height)/2) + 4rem));
    }
}

.order-detail-product-price-label {
    aspect-ratio: 1/1;
    display: flex;
    box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width rgba($secondary,0.3);
    transform: rotate(9deg);
    flex-direction: column;
    justify-content: center;
    padding-inline: 2rem;
    text-align: center;
    line-height: 1.6em;
    font-size: 1.2em;
    right: 2.5rem;
    top: 1.5rem;

    * {
        display: flex;
    }

    strike {
        margin-top: -0.2em !important;
        font-weight: 400;
    }
}

.order-details {
    .order-details-price {
        min-width: 150px;
    }
}

.re-enter-code-desktop {
    border-top-left-radius: 0 !important;
}

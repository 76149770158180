@use 'sass:math';

// Dev grid
.dev-helpers {
    z-index: $zindex-fixed;
    overflow: visible;
    position: fixed;
    bottom: $spacer;
    left: $spacer;

    .active-mobile-menu & {
        z-index: ($zindex-sticky - 100);
    }

    @include media-breakpoint-up(xl) {
        max-width: 480px;
    }

    body:not(:hover) & {
        display: none;
    }
}

.dev-grid {
    background: $white;
    color: $font;
    border-radius: $border-radius;
    padding: math.div($spacer,3) $spacer;
    box-shadow: 0 0 math.div($spacer,2) rgba(0,0,0,0.1);

    .custom-control {
        padding-left: 0;
    }

    .dev-grid-toggle {
        position: relative;
        z-index: 3;

        &:checked {
            ~ .dev-grid-container {
                display: block;
            }
        }
    }

    .dev-grid-label {
        position: relative;
        user-select: none;
        color: inherit;
        z-index: 3;
    }

    .dev-grid-container {
        top: 0; left: 0; right: 0;
        pointer-events: none;
        position: fixed;
        display: none;
        z-index: 2;
    }

    [class^="container"] {
        position: relative;

        [class^="col"] {
            position: relative;

            &::before {
                background: rgba(0,0,0,0.1);
                display: block;
                height: 100vh;
                content: '';
                width: 100%;
            }
        }
    }
}

.dev-seo {
    background: $white;
    color: $font;
    border-radius: $border-radius;
    padding: math.div($spacer, 3) $spacer;
    box-shadow: 0 0 math.div($spacer, 2) rgba(0, 0, 0, 0.1);
    opacity: 1;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}

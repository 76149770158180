.video-img {
    position: relative;
    display: block;

    .video-img-overlay {
        transform: translate(-50%,-50%) scale(1);
        transition: 200ms transform ease-in;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        top: 50%; left: 50%;
        text-align: center;
        position: absolute;
        display: flex;
        z-index: 3;

        .svg-icon {
            transform: unset !important;
            top: unset; left: unset;
            position: static;

            &.bg-primary {
                transition: box-shadow 300ms;
                box-shadow: 0 0 0 0 rgba($primary, 0.25);
            }
        }

        &-text {
            transition: background 300ms;
            background: rgba(0,0,0,0.3);
            border-radius: 1rem;
            position: relative;
            padding: 2px 12px;
            margin-top: 12px;
            color: $white;
            z-index: 2;
        }
    }

    &:hover {
        .video-img-overlay {
            .svg-icon.bg-primary {
                transition: box-shadow 300ms;
                box-shadow: 0 0 0 0.35rem rgba($primary, 0.25);
            }
        }

        .video-img-overlay-text {
            transition: background 300ms;
            background: rgba(0,0,0,0.4);
        }
    }

    &:focus {
        .video-img-overlay {
            .svg-icon {
                transition: box-shadow 300ms;
                box-shadow: 0 0 0 0.35rem rgba($primary, 0.4);
            }
        }

        .video-img-overlay-text {
            transition: background 300ms;
            background: rgba(0,0,0,0.5);
        }
    }
}

.image-button {
    position: relative;

    .btn {
        position: absolute;
        bottom: $spacer*3; left: 0;
        z-index: 2;
    }
}

// News
.body_news {
    .content-section-editor,
    .content-section-image_text {
        h3 {
            color: $primary;

            strong {
                font-weight: 500;
                color: $secondary;
            }
        }
    }
}

// Section met stretch columns
section:has(.stretch-col-lg-6) {
    @include media-breakpoint-down(lg) {
        .col-text {
            padding-top: 1rem;
        }

        .col-image {
            img {
                aspect-ratio: 16/9;
                object-fit: cover;
                height: auto;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    section:has(.stretch-col-lg-6) {
        padding: 0 !important;

        .col-lg-6 {
            margin-bottom: 0 !important;
        }

        .col-text {
            padding-block: 4rem;
            align-self: center;

            @include media-breakpoint-up(lg) {
                padding-block: 12rem;
            }
        }

        [class*="row-reverse"] {
            @include media-breakpoint-up(lg) {
                .offset-lg-1 {
                    margin-left: unset;
                    margin-right: (100%/12);
                }
            }
        }
    }

    .stretch-col-lg-6 {
        position: relative;

        img {
            position: absolute;
            top: 0;
            height: 100%;
            max-width: calc(100% + 1rem);
            width: calc(100% + 1rem);
        }

        .row:not([class*="row-reverse"]) & {
            @include new-stretch-till-edge (
                $col-container: 6,
                $col-offset: 0,
                $col-padding: $spacer,
                $site-wrapper-width: $site-wrapper-width,
                $direction: right
            );

            img {
                object-fit: cover;
                right: 0;
            }
        }

        .row[class*="row-reverse"] & {
            @include new-stretch-till-edge (
                $col-container: 6,
                $col-offset: 0,
                $col-padding: $spacer,
                $site-wrapper-width: $site-wrapper-width,
                $direction: left
            );

            img {
                object-fit: cover;
                left: 0;
            }
        }
    }
}

.max-h-100svh {
    max-height: 100svh;
}


body:not(.body_pillow) {
    .content-section-image_text:not(.stretch) {
        .col-image {
            img {
                object-fit: contain;
            }
        }
    }
}

// Pillow default sections
.body_pillow {
    .content-section-image_text {
        .container {
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
        }

        .flex-row-reverse {
            @include media-breakpoint-down(lg) {
                padding-bottom: $spacer*2;
            }

            .col-image {
                img {
                    @include stretch-till-edge(
                        $mixin-stretch-till-edge--col-container: 6,
                        $mixin-stretch-till-edge--col-padding: 0,
                        $mixin-stretch-till-edge--site-wrapper-width: 100vw,
                        $mixin-stretch-till-edge--direction: left
                    )
                }
            }
        }

        .col-text {
            padding-top: $spacer*3;
            padding-bottom: $spacer*3;
        }

        .col-image {
            img {
                @include stretch-till-edge(
                    $mixin-stretch-till-edge--col-container: 6,
                    $mixin-stretch-till-edge--col-padding: 0,
                    $mixin-stretch-till-edge--site-wrapper-width: 100vw,
                    $mixin-stretch-till-edge--direction: right
                );

                @include media-breakpoint-down(lg) {
                    width: 100% !important;
                }

                max-height: 500px;
                object-fit: contain;
            }
        }
    }
}

.content-section-image_text {
    .col-image {
        @include media-breakpoint-down(sm) {
            width: auto !important;
            max-width: 100vw !important;
            padding-inline: 0 !important;
            margin-inline: -1rem !important;
        }
    }
}

.braintree-form__notice-of-collection {
    padding-left: 20px !important;
}

.ff-brand {
    font-family: "junegull", sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
}

h2 {
    span {
        color: $secondary;
    }
}

.social-container {
    &:not(:has(ul)) {
        display: none !important;
    }
}

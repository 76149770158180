/*
 * Description: Btn mobile menu
 * Version: v1.0.0
 */

@mixin btn-mobile-menu(
    $mixin_btn-mobile-menu--background:       false,
    $mixin_btn-mobile-menu--color:            $secondary,
    $mixin_btn-mobile-menu__active--color:     false,
    $mixin_btn-mobile-menu__hover--color:      false,
    $mixin_btn-mobile-menu--size:             16px,
    $mixin_btn-mobile-menu--padding:          1rem
) {
    @if $mixin_btn-mobile-menu--padding == 0 {
        padding: calc((#{$mixin_btn-mobile-menu--size} * 0.6)) $mixin_btn-mobile-menu--padding;
    } @else {
        padding: calc((#{$mixin_btn-mobile-menu--size} * 0.6) + #{$mixin_btn-mobile-menu--padding}) $mixin_btn-mobile-menu--padding;
    }
    height: round($mixin_btn-mobile-menu--size*1.5);
    width: round($mixin_btn-mobile-menu--size*1.5);
    transition: 300ms all ease-in;
    align-self: center;
    position: relative;
    cursor: pointer;
    display: flex;
    right: unset;
    left: 0;
    top: 0;

    @if $mixin_btn-mobile-menu--background {
        background-color: $mixin_btn-mobile-menu--background;
    }

    &:hover,
    .active-mobile-menu & {
        transition: 300ms all ease-in;

        .lines {
            background-color: $mixin_btn-mobile-menu__hover--color;

            &::after,
            &::before {
                background-color: $mixin_btn-mobile-menu__hover--color;
            }
        }
    }

    html:not(.active-mobile-menu) &:hover {
        .lines {
            &::before {
                margin-top: 1px;
            }

            &::after {
                margin-top: -1px;
            }
        }
    }

    .lines {
        @include transition;
        background-color: $mixin_btn-mobile-menu--color;
        display: inline-block;
        width: round($mixin_btn-mobile-menu--size*1.5);
        position: relative;
        height: round($mixin_btn-mobile-menu--size*0.2);

        &::after,
        &::before {
            background-color: $mixin_btn-mobile-menu--color;
            position: absolute;
            height: inherit;
            width: inherit;
            content: '';
        }

        &::before {
            @include transition(
                $mixin_transition--speed: 'fast'
            );

            transform: translateY(calc((-#{$mixin_btn-mobile-menu--size}*0.5) - 1px));

            @at-root .btn-mobile-menu.opened .lines::before,
            .active-mobile-menu & {
                @include transition(
                    $mixin_transition--speed: 'fast'
                );
                background-color: $mixin_btn-mobile-menu__active--color;
                transform: rotate(-45deg);
                transform-origin: center;
            }
        }

        &::after {
            @include transition(
                $mixin_transition--speed: 'fast'
            );
            transform: translateY($mixin_btn-mobile-menu--size*0.5);

            @at-root .btn-mobile-menu.opened .lines::after,
            .active-mobile-menu & {
                background-color: $mixin_btn-mobile-menu__active--color;
                transform: rotate(45deg);
                transform-origin: center;
            }
        }

        @at-root .btn-mobile-menu.opened .lines,
        .active-mobile-menu & {
            @include transition(
                $mixin_transition--speed: 'fast'
            );
            background-color: rgba($mixin_btn-mobile-menu--color, 0);
        }
    }
}

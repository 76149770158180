// Grays
$white:                                 #fff !default;
$gray-100:                              #f8f9fa !default;
$gray-200:                              #e9ecef !default;
$gray-300:                              #dee2e6 !default;
$gray-400:                              #ced4da !default;
$gray-500:                              #adb5bd !default;
$gray-600:                              #6c757d !default;
$gray-700:                              #495057 !default;
$gray-800:                              #343a40 !default;
$gray-900:                              #212529 !default;
$black:                                 #12171a;

$grays: (
    "100":                              $gray-100,
    "200":                              $gray-200,
    "300":                              $gray-300,
    "400":                              $gray-400,
    "500":                              $gray-500,
    "600":                              $gray-600,
    "700":                              $gray-700,
    "800":                              $gray-800,
    "900":                              $gray-900
) !default;

// Colors
$blue:                                  #0d6efd !default;
$indigo:                                #6610f2 !default;
$purple:                                #6f42c1 !default;
$pink:                                  #d63384 !default;
$red:                                   #dc3545 !default;
$orange:                                #fd7e14 !default;
$yellow:                                #ffc107 !default;
$green:                                 #198754 !default;
$teal:                                  #20c997 !default;
$cyan:                                  #0dcaf0 !default;

$colors: (
    "blue":                             $blue,
    "indigo":                           $indigo,
    "purple":                           $purple,
    "pink":                             $pink,
    "red":                              $red,
    "orange":                           $orange,
    "yellow":                           $yellow,
    "green":                            $green,
    "teal":                             $teal,
    "cyan":                             $cyan,
    "white":                            $white,
    "gray":                             $gray-600,
    "gray-dark":                        $gray-800
) !default;

// Theme colors
$primary:                               #005C6B;
$secondary:                             #f0a32d;
$tertiary:                              #E6EFF1;
$success:                               $green !default;
$info:                                  $cyan !default;
$warning:                               $yellow !default;
$danger:                                $red !default;
$light:                                 #e6eff1;
$dark:                                  #00282f;
$default:                               #666;
$font:                                  #002F36;

$theme-colors: (
    "primary":                          $primary,
    "secondary":                        $secondary,
    "tertiary":                         $tertiary,
    "success":                          $success,
    "info":                             $info,
    "warning":                          $warning,
    "danger":                           $danger,
    "light":                            $light,
    "dark":                             $dark,
    "default":                          $default
);

@mixin scrollbars(
    $mixin_scrollbars--visible: true
) {
    @if $mixin_scrollbars--visible == true {
        overflow-y: scroll;
    }
}

html {
    background-color: $dark;
    @include scrollbars(
        $mixin_scrollbars--visible: $html--default-scrollbar
    );
}

body {
    background-color: $dark;
    font-family: $font-family-base;
    flex-direction: column;
    min-height: 100vh;
    display: flex;
    color: $white;

    footer {
        margin-top: auto !important;
    }
}

.site-wrapper {
    color: $font;
    max-width: 100%;
    flex-direction: column;
    position: relative;
    min-height: 100vh;
    overflow: clip;
    display: flex;
    margin: auto;
    width: 100%;

    main {
        background-color: $white;
        flex-grow: 1;

        section:not(.section-fullwidth):not(.stretch) {
            padding: 3rem 0;

            @include media-breakpoint-up(lg) {
                padding: 6rem 0;
            }

            &:is(.bg-white, .bottom-white) + section.bg-white,
            &:is(.bg-light, .bottom-light) + section.bg-light,
            &:is(.bg-secondary, .bottom-secondary) + section.bg-secondary {
                padding-top: 0;
            }
        }

        &:has(section:only-child .stretch-col-lg-6) {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            section {
                flex-grow: 1;
                display: flex;

                .container,
                .container .row {
                    flex-grow: 1;
                    display: flex;
                }
            }
        }
    }
}

.container {
    @include media-breakpoint-down(sm) {
        padding-left: $spacer*2 !important;
        padding-right: $spacer*2 !important;
    }
}


b, strong {
    font-weight: $font-weight-bold;
}

.text-lg {
    font-size: 18px;
}


// Default fluid images
img {
    @extend .img-fluid;
}


// -- Form styling

// Custom control label
.custom-control-label {
    &::after,
    &::before {
        top: 0.3em;
    }
}

// Label cursor fix
label[for] {
    cursor: pointer;
}


// Buttons
.btn {
    border-radius: 0 $spacer*2 $spacer*2 $spacer*2;
    position: relative;
    color: $white;

    &-primary,
    &-default,
    &-success,
    &-danger {
        &,
        &:focus,
        &:hover {
            color: $white !important;
        }
    }


    &-primary,
    &-secondary {
        &:not(.btn-clean) {
            padding-inline-end: 2.2rem;

            &::after {
                height: auto;
                width: 2.2em;
                color: $secondary;
                position: absolute;
                top: 0.85rem;
                right: -1.1em;
                transition: all .2s ease-in-out;
            }

            &:hover {
                color: $white !important;
                background-color: darken($primary,10%) !important;

                &::after {
                    right: -1.7em;
                }
            }
        }
    }

    &-primary {
        &:not(.btn-clean) {
            &::after {
                content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47.73 25.36'><path d='M35.29,.12l12.33,12.33c.14,.11,.14,.34,0,.48l-12.33,12.33c-.14,.14-.34,.14-.48,0l-1.59-1.59,9.73-9.72H1.39C.62,13.95,0,13.33,0,12.56c0-.74,.62-1.36,1.39-1.36H42.72L33.22,1.69l1.59-1.59c.14-.14,.34-.14,.48,0h0Z' fill='%23F0A32D'/></svg>");
            }

            &:hover {
                background-color: darken($primary,10%) !important;
            }

            .bg-dark &,
            .bg-secondary & {
                &::after {
                    content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47.73 25.36'><path d='M35.29,.12l12.33,12.33c.14,.11,.14,.34,0,.48l-12.33,12.33c-.14,.14-.34,.14-.48,0l-1.59-1.59,9.73-9.72H1.39C.62,13.95,0,13.33,0,12.56c0-.74,.62-1.36,1.39-1.36H42.72L33.22,1.69l1.59-1.59c.14-.14,.34-.14,.48,0h0Z' fill='%23ffffff'/></svg>");
                    color: $white;
                }
            }
        }
    }

    &-secondary {
        &:not(.btn-clean) {
            &::after {
                content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47.73 25.36'><path d='M35.29,.12l12.33,12.33c.14,.11,.14,.34,0,.48l-12.33,12.33c-.14,.14-.34,.14-.48,0l-1.59-1.59,9.73-9.72H1.39C.62,13.95,0,13.33,0,12.56c0-.74,.62-1.36,1.39-1.36H42.72L33.22,1.69l1.59-1.59c.14-.14,.34-.14,.48,0h0Z' fill='%23005472'/></svg>");
            }

            &:hover {
                background-color: darken($secondary, 10%) !important;
                border-color: darken($secondary, 10%) !important;
            }

            .bg-dark &,
            .bg-primary &,
            .header-section & {
                &::after {
                    content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47.73 25.36'><path d='M35.29,.12l12.33,12.33c.14,.11,.14,.34,0,.48l-12.33,12.33c-.14,.14-.34,.14-.48,0l-1.59-1.59,9.73-9.72H1.39C.62,13.95,0,13.33,0,12.56c0-.74,.62-1.36,1.39-1.36H42.72L33.22,1.69l1.59-1.59c.14-.14,.34-.14,.48,0h0Z' fill='%23ffffff'/></svg>");
                    color: $white;
                }
            }
        }
    }
}

a.readmore {
    @extend .link-inverted;
    font-weight: 700;
}


// Headings
h1 {
    color: $primary;

    strong {
        font-weight: 500;
        color: $secondary;
    }
}

h2 {
    margin-bottom: $spacer !important;
    color: $primary;

    strong {
        font-weight: 500;
        color: $secondary;
    }
}

.bg-primary, .bg-secondary {
    h2 {
        color: $white;
    }
}

h3, .h3 {
    color: $primary;

    strong {
        font-weight: 500;
        color: $secondary;
    }
}


// Forms
form {
    .form-control, .form-select {
        padding-inline-end: 2rem !important;
        color: $primary;
        border-color: $primary;
        border-radius: 0 $spacer $spacer $spacer !important;
    }

    .input-group {
        border-top-left-radius: 0 !important;
        border-color: $primary;
    }

    .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        margin-left: calc(var(--bs-border-width) * -1);
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: $spacer !important;
        background-color: #e6eff1;
        border-color: $primary;
    }
}

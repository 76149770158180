/*
 * Description: Toggle list
 * Version: v1.0.0
 */

@mixin toggle-list(
    $mixin_toggle-list--class:                              'toggle-list',
    $mixin_toggle-list--margin:                             false,
    $mixin_toggle-list--padding:                            false,
    $mixin_toggle-list-item--border:                        1px solid $list-group-border-color,
    $mixin_toggle-list-item--margin:                        false,
    $mixin_toggle-list-item--padding:                       false,
    $mixin_toggle-list-item--hover-bg-color:                lighten($light,5%),
    $mixin_toggle-list-item--active-color:                  inherit,
    $mixin_toggle-list-item--active-border:                 false,
    $mixin_toggle-list-item--active-bg-color:               $light,
    $mixin_toggle-list-item-question--border:               false,
    $mixin_toggle-list-item-question--bg-color:             false,
    $mixin_toggle-list-item-question--padding:              false,
    $mixin_toggle-list-item-question--color:                false,
    $mixin_toggle-list-item-answer--border:                 false,
    $mixin_toggle-list-item-answer--bg-color:               false,
    $mixin_toggle-list-item-answer--padding:                false,
    $mixin_toggle-list-item-answer--color:                  false,
) {
    padding: $mixin_toggle-list--padding;
    margin: $mixin_toggle-list--margin;
    list-style: none;

    .#{$mixin_toggle-list--class}-item {
        padding: $mixin_toggle-list-item--padding;
        margin: $mixin_toggle-list-item--margin;
        border: $mixin_toggle-list-item--border;

        &:not(:first-child, .active) {
            border-top: 0;
        }

        @if $mixin_toggle-list-item--hover-bg-color != false {
            &:hover {
                background-color: $mixin_toggle-list-item--hover-bg-color;
            }
        }

        &.active {
            color: $mixin_toggle-list-item--active-color;

            @if $mixin_toggle-list-item--active-bg-color != false {
                background-color: $mixin_toggle-list-item--active-bg-color;
            }

            @if($mixin_toggle-list-item--active-border) {
                border-color: $mixin_toggle-list-item--active-border;
            }

            .svg-icon {
                transform: rotate(180deg);
            }
        }
    }

    .#{$mixin_toggle-list--class}-item-question {
        background-color: $mixin_toggle-list-item-question--bg-color;
        padding: $mixin_toggle-list-item-question--padding;
        border: $mixin_toggle-list-item-question--border;
        color: $mixin_toggle-list-item-question--color;

        .svg-icon {
            transition: 150ms transform ease;
            transform: none;
        }
    }

    .#{$mixin_toggle-list--class}-item-answer {
        display: none;
    }

    .#{$mixin_toggle-list--class}-item.active .#{$mixin_toggle-list--class}-item-answer {
        padding: $mixin_toggle-list-item-answer--padding;
        border: $mixin_toggle-list-item-answer--border;
        display: block;

        @if $mixin_toggle-list-item-answer--bg-color != false {
            background-color: $mixin_toggle-list-item-answer--bg-color;
        }

        @if $mixin_toggle-list-item-answer--color != false {
            color: $mixin_toggle-list-item-answer--color;
        }
    }
}

.js-mobile-header [data-menu-wrapper="header"] {
    .ms-auto {
        margin-left: auto !important;
    }
}

.header-section {
    background-color: transparent;
    position: absolute;
    z-index: 9999;
    color: $white;
    right: 0;
    left: 0;
    top: 0;

    body.body_home & {
        transition: 150ms background-color ease;
    }

    .scroll & {
        background-color: $dark;
        position: fixed;

        html:not(.active-mobile-menu) {
            box-shadow: 0 10px 8px -8px rgba(0,0,0,0.1);
        }

        .container {
            margin-block: 1rem !important;

            @include media-breakpoint-up(lg) {
                margin-block: 1.5rem !important;
            }
        }

        @include media-breakpoint-up(lg) {
            .logo-container {
                svg {
                    transition: 150ms height ease;
                    height: 56px;
                }
            }
        }

        .menu-container {
            margin-top: 0 !important;
        }
    }

    .container {
        z-index: $zindex-sticky;
        position: relative;
    }

    .btn-mobile-menu-wrapper {
        @include media-breakpoint-down(lg) {
            @include icon-spacer();
        }

        .js-mobile-header & {
            @include icon-spacer();
        }

        .btn-mobile-menu {
            @include btn-mobile-menu(
                $mixin_btn-mobile-menu--background: false,
                $mixin_btn-mobile-menu--color: $white,
                $mixin_btn-mobile-menu__active--color: false,
                $mixin_btn-mobile-menu__hover--color: false,
                $mixin_btn-mobile-menu--size: 16px,
                $mixin_btn-mobile-menu--padding: 0
            );
        }
    }

    .logo-container {
        svg {
            transition: 150ms height ease;
            height: 40px;
            width: auto;

            @media all and (min-width: 1400px) {
                transition: 150ms height ease;
                height: 97px;
            }
        }
    }

    .popup-item {
        .body-text {
            display: none;

            &.active {
                display: block;
                position: relative;
                transition: 2000ms all ease-in-out;
                animation: fadeIn 2000ms;
                animation-fill-mode: forwards;
            }

            .popup {
                width: 360px;
                position: absolute;
                top: 5px; right: -20px;
                box-shadow: 2px 2px 10px rgba(0,0,0,0.2);

                p {
                    font-size: 15px;
                    color: $font;
                    white-space: normal;
                }

                .btn-primary {
                    font-size: 16px;

                    &::after {
                        width: 30px;
                        right: -18px;
                    }

                    &:hover {
                        &::after {
                            right: -25px;
                        }
                    }
                }

                button {
                    background: none;
                    border: 0;

                    svg {
                        margin: -10px -10px 0 0;
                        width: 20px;
                    }
                }
            }
        }
    }

    .menu-container {
        @include media-breakpoint-down(lg) {
            @include icon-spacer();
        }
        .js-mobile-header & {
            @include icon-spacer();
        }

        .svg-icon {
            font-size: 35px;
        }

        a:not(.btn) {
            .svg-icon {
                &:hover {
                    svg {
                        path {
                            fill: $secondary;
                        }
                    }
                }
            }
        }
    }

    .btn {
        font-size: 20px;
    }

    .header-menu {
        .menu {
            @include menu-horizontal(
                $mixin_menu-horizontal--x-margin:                         -1rem,
                $mixin_menu-horizontal--y-margin:                         0,
                $mixin_menu-horizontal-li--y-padding:                     0,
                $mixin_menu-horizontal-li--x-padding:                     0,
                $mixin_menu-horizontal-link-element:                      '.link',
                $mixin_menu-horizontal-link--padding:                     0.4rem 1.5rem,
                $mixin_menu-horizontal-link--color:                       $white,
                $mixin_menu-horizontal-link--decoration:                  none,
                $mixin_menu-horizontal-link__hover--color:                $secondary,
                $mixin_menu-horizontal-link__hover--decoration:           none,
                $mixin_menu-horizontal-link--border-color:                currentColor,
                $mixin_menu-horizontal-link--border-height:               0
            );

            @include media-breakpoint-down(xl) {
                .menu-link {
                    padding-left: 10px !important;
                    padding-right: 10px !important;
                }
            }

            .menu-li.active .menu-link .link {
                color: $secondary;
            }

            .menu-link {
                font-size: 20px;
            }

            .submenu {
                @include dropdown(
                    $mixin_dropdown-content--width: 240px
                );

                &.active-dropdown {
                    .menu-link {
                        color: $primary;

                        .link {
                            color: inherit;
                        }
                    }
                }

                .dropdown-ul {
                    @extend .list-group;
                    @extend .list-group-flush;

                    .dropdown-li {
                        @extend .list-group-item;

                        &.active {
                            background-color: $light;
                            border-color: $light;
                            color: inherit;
                        }

                        .dropdown-link {
                            @extend .text-decoration-none;
                        }
                    }
                }
            }
        }
    }

    .header-language {
        .header-language_dropdown {
            @include dropdown(
                $mixin_dropdown_trigger--padding: 0.75rem 1rem,
                $mixin_dropdown-trigger--border-radius: 0,
                $mixin_dropdown-trigger__active: false,
                $mixin_dropdown-trigger__active--bg: $white,
                $mixin_dropdown-trigger__active--color: $primary,

                $mixin_dropdown-arrow: true,
                $mixin_dropdown-arrow--z-index: -1,
                $mixin_dropdown-arrow--size: 8px,
                $mixin_dropdown-arrow--corner: 1.15,

                $mixin_dropdown-content--border-radius: 4px,
                $mixin_dropdown-content--position-left: 1rem,
                $mixin_dropdown-content--bg: $white,
                $mixin_dropdown-content--color: $primary,
                $mixin_dropdown-content--box-shadow: $box-shadow,
            )
        }

        .svg-icon {
            font-size: 25px;
        }
    }

    .container-fluid.bg-secondary {
        font-size: 18px;
    }
}

[data-placeholder-element=".header-section"] {
    background-color: $dark;
    height: 52px;

    @include media-breakpoint-up(lg) {
        height: 152px;
    }

    @include media-breakpoint-up(xxl) {
        height: 193px;
    }
}
